document.addEventListener('DOMContentLoaded', function() {
//   $('.review-col-list').owlCarousel({
//       center: true,
//       margin: 10,
//       nav: false,
//       loop: true,
//       autoplay: true,
//       autoplayTimeout: 5000,
//       autoplayHoverPause: true,
//       animateIn: true,
//       navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
//       responsive: {
//           0: {
//               items: 1
//           },
//           600: {
//               items: 3
//           },
//           1000: {
//               items: 4
//           }
//       }
//   });

  var cookieButton = document.getElementsByClassName('button-cookies')[0],
      cookieBanner = document.getElementsByClassName('cookie-popup')[0];
       
  // click event on button to add the cookie and remove the HTML banner
  cookieButton.addEventListener('click', function() {
      var cookieData = 'CookieConsent=true; path=/; expires=';
      var date = new Date();
      var expirationDate = date.getTime() + 31536000000;

      date.setTime(expirationDate);
      cookieData += date.toUTCString();
      document.cookie = cookieData;

      // fades out cookie banner
      cookieBanner.classList.add('hidden');
  });
      
  // checks to see if cookie has been set and if so keeps the banner hidden
  if (document.cookie.split(';').filter(function(item) {
      return item.trim().indexOf('CookieConsent=') == 0;
  }).length) {
      // prevents cookie notice showing on next visit
      cookieBanner.classList.remove('hidden');
  } else {
      cookieBanner.classList.add('hidden');
  }
});